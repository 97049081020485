.root-panel {
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
  width: 100vw;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: xx-large;
  padding-bottom: 5vh;
}

td {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  border-top: 1px solid #e1f1ff;
}

th {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px 0;
}