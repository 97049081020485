#wastopwrap{
  width: 90%;
  height: 90%;
  min-width: 1000px;
  min-height: 561px;
  background-color: #e0e2e2;
}

#topwrap{
  width: calc(100vw - var(--scrollbar-width));
  height: calc(100vh - var(--scrollbar-height));
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #e0e2e2;
}

.columnSettings{
  /*float: left;
  width: 33.3%;
  height: 100%;*/
  flex: 1 1 33%;
  margin: 0;
  height: 100%;
}

#col1{
  background-color: #e0e2e2;
}

#col2{
  background-color: #e0e2e2;
}

#col3{
  background-color: #e0e2e2;
}

#col1 > * {
  margin-left: 5%;
  margin-right: 5%;
}

#col3 > * {
  margin-left: 5%;
  margin-right: 5%;
}

.back-btn {
  width: 10%;
  display: inline-block;
  vertical-align:sub;
  margin-right: 1vw;
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  cursor: pointer;
}

.headline-text{
  display: inline-block;
  width: 85%;
  font-size: 2.6vh;
  font-weight: bolder;
}

.subheading-text {
  font-weight: bolder;
  font-size: 2vh;
}

#progress-bar{
  width: 80%;
  margin-right: 1vw;
  flex: 1 1 auto;
}

#col1 input[type=text] {
  /*margin-top: 2vh;
  margin-bottom: 2vh;*/
  font-size: 1.5vh;
  height: 4vh;
  font-family: arial;
  border-radius: 8px;
  border: none;
  width:98%;
  padding-left: 2%;
}

.longform-input{
  padding: 2%;
  font-size: 1.5vh;
  resize: none;
  font-family: arial;
  height: 10vh;
  width: 96%;
  border-radius: 10px;
  border: none;
}

/*
.upload-container{
  width: 100%;
  max-width: 500px;
}
*/ 
.file-container{
  display: flex;
}

.upload-container {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 75%;
  height: 4.7vw;
  display: flex;
  margin-left: 0;
}


.upload-container label{
  float: left;
  margin-top: 1.35vw;
  margin-left: 1vw;
}

.instruction{
  display: inline-block;  
  margin-left: 0.4vw;
  font-size: 0.8vw;
  margin-top:1.9vw;
}

.item-container:nth-of-type(2){
  float: right;
}

.current-selection{
  max-width: 50%;
  /*float: right;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;*/
  display: flex;
  margin: auto;
  margin-right: 1vw;
}

.selected-text{
  font-size: 0.8vw;
  /*float: right;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;
  margin-left: 5%;*/
  display: flex;
  margin: auto;
  margin-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-x {
  display: inline-block;
  background-color: lightgray;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 0.8vw;
  border-radius: 5px;
  border:none;
  cursor: pointer;
  margin-right: 0.05vw;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;
  margin-left: 15%;
  width: 1vw;
  text-align: center;
  text-transform: uppercase;
}

.icon-prev{
  float: right;
  max-width: 4.7vw;
  max-height: 4.7vw;
  margin: auto;
  margin-right: 0;
}
.icon-prev-image{
  max-height: 100%;
  max-width: 100%;
  border-radius: 8px;
}

input[type=file] {
  display: none;
}

.Toastify__toast--warning {
  color: black;
  font-weight: bold;
}

.Toastify__toast--success {
  font-weight: bold;
}

#audio-playback{
  display:inline-grid;
  margin:auto;
  width:80%;
  height:1.25vw;
  margin-bottom: 0.5vw;
  align-self:center;
}

.size-rot-input{
  width: 12%;
  font-size: 1.5vh;
  height: 4vh;
  font-family: arial;
  border-radius: 8px;
  border: none;
  padding-left: 2%;
}

.size-rot-label{
  margin-left: 5%;
  margin-right: 5%;
  font-weight: 600;
  font-size: 1.5vh;
}

.hide-checkbox {
  height: 0;
  width: 0;
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.tags-button {
  display: inline-block;
  text-align: center;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight:600;
  width: 5vw;
  height: 1.25vw;
  padding: 5px;
  font-size: 0.8vw;
  margin-right: 1.5vw;
  border-radius: 12px;
  line-height: 1.25vw;
  border:none;
  cursor: pointer;
}

 .hide-checkbox:checked + .tags-button{
  width: 5vw;
  height: 1.25vw;
  background: black;
  color: white;
}

.supersize-checkbox{ 
  border: none;
  outline: none;
  display: inline-block;
  width: 5%;
  height: 1em;
}

.license-text{
  display: inline-block;
  height: 1em;
  width: 85%;
  font-size: 0.8vw;
  margin-left: 1%;
}

.small-button {
  background-color: white;
  float: right;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  padding: 0.4vw 0.7vw;
  font-size: 1vw;
  margin-top: 0.8vw;
  border-radius: 7px;
  border:none;
  cursor: pointer;
}

.currently-selected-text {
  float: right;
  
  font-size: 0.8vw;
  overflow: hidden;
}

.color-picker {
  display: flex;
  margin: auto;
  border: 1px black solid;
}

.media-controls{
  border:none;
  cursor: pointer;
  background-color:transparent;
  background-repeat:no-repeat;
  margin-right: 1vw;
}