.privacy-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .popup-text {
    position: relative;
    width: 80vw;
    margin: 0 auto;
    height: auto;
    max-height: 80%;
    margin-top: 7vh;
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  .privacy-close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: 8vw;
    top: 5.5vh;
    background: #ededed;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 30px;
  }

  .privacy-ul {
    list-style-type: circle;
    list-style-position: inside;
  }

  .better-h2 {
    font-weight: bolder;
    font-size: large;
  }