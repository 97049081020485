.selectionmenu-popup {
  position: fixed;
  /*background: #00000050*/
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
 
.selectionmenu {
  position: relative;
  width: 100vw;
  margin: 0 auto;
  /*height: auto;*/
  max-width: 170px;
  /*max-height: 170px;
  height: 100vh;*/
  margin-top: calc(3vh + 2vw);
  margin-right: calc(3vh + 8.8vw);
  background: #fff;
  border-radius: 10px;
  padding: 15px 10px 0 10px;
  border: 1px solid #999;
  overflow: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.selectionmenu li {
  padding: 3px 0;
}

.selectionmenu li:last-child {
  margin-bottom: 10px;
}

.selectionmenu li button {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  /*border-bottom: solid black;*/
  border-width: 1px;
  cursor: pointer;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size:small;
}

.selectionmenu li:first-child {
  margin-right: 10px;
}

.selectionmenu li:last-child {
  margin-left: 10px;
}

.selectionmenu li:hover {
  outline: solid black;
  outline-width: 1px;
}

.selectionmenu li button:hover {
  border:none;
}

.sidewaysli {
  display: inline-block;
  /* You can also add some margins here to make it look prettier */
  zoom:1;
  *display:inline;
  /* this fix is needed for IE7- */
}