.parent-tag {
    border: 1px solid gray; 
    width: 70%; 
    height: 5em; 
    float: right;
    overflow: scroll;
    overflow-x: hidden;
}

.tag-input {
    width: 5em; 
    border: none;
}

.tag-input:focus {
    outline:none
}

.tag-item{
  max-width: 4em;
  max-height: 1em;
}

.tag-text{
  display: inline-block;
  text-overflow: ellipsis;
}

.tag-btn{
    display: inline-block;
    position: relative;
    width: 7em;
    height: 2em;
    text-align: left;
    display: inline-block;
}

.tag-btn img {  
	display: inline-block;
    float: right;
    border-radius: 5px;
    width: 15px;
    height: 15px;
}

.tag-btn span{
    display:inline-block;
    max-width: 4.5em;
    vertical-align:left;
    white-space: nowrap; 
    overflow:hidden;
    text-overflow:ellipsis;
}

.tag-container{
    display: inline-block;
    position: relative;
    max-width: 7em;
    height: 1.7em;
    border: 1px solid black;
    border-radius: 7%;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: white;
}

.texedit-btn{
    display: inline-block;
    position: relative;
    max-width: 5em;
    height: 2em;
    text-align: left;
    display: inline-block;
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    cursor: pointer;
}

.btnspan{
    display:inline-block;
    max-width: 4.5em;
    vertical-align:left;
    white-space: nowrap; 
    overflow:hidden;
    text-overflow:ellipsis;
}

.btnimg{
    display: inline-block;
    float: right;
    border-radius: 5px;
    max-width: 2em;
    height: 2em;
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    cursor: pointer;
}

.btninput{
    border: none;
    font-size: small;
}

.btninput:focus{
    outline:none;
}