.profilepanel {
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
  width: 100vw;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  text-align: left;
  /*text-transform: uppercase;*/
}

.title {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: xx-large;
  padding-bottom: 5vh;
}

.save-btn {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 2px;
  cursor: pointer;
  float: right;
  border-style: solid;
  border-width: 1px;
  font-size: 1em;
  font-weight: bold;
  color: black;
  border: none;
}

.bold-label{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  width: 20%;
  display: inline-flex;
}

.div-padding{
  margin: 20px 30px;
}

.div-padding-pic{
  margin: 20px 30px;
  height: 100px;
}
.profiletextinput{
  display: inline-block;
  float: right;
  width: 70%;
  outline: black solid 1px;
}

.profilepiccontainer{
  display: inline-block;
  float: right;
  width: 70%;
}

.profilefileinput{
  display: inline-block;
  margin-left: 0;
  width: 100px;
  background-color: white;
}

.inputtext{
  display: inline-block;
  float:center;
}

.profilepiccontainer input[type=file] {
  display: none;
}

.profilelonginput{
  display: inline-block;
  float: right;
  resize: none;
  height: 70px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 70%;
}