.container{
    width: 100vw;
    height: 100vh;

}
.leftPanel {
    height: 100%;
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6fccdc;
}
.rightPanel {
    height: 100%;
    width: 50%;
    float: right;
    background-color: #f04c51;
    display: grid;
    grid-template-columns: 1fr;

    grid-template-areas: 
    'header'
    'main'
    'footer';
}

.login-wrapper{
    max-width: 75%;
}

#logo{
    padding-right: 2vh;
    padding-top: 2vh;
    float: right;
    max-width: 3vw;
}

.homelogin-button{
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    margin: 2vh 0.5vw 2vh 0;
    padding: 0.3em 1.2em;
}

.homelogin-button:hover{
    cursor: pointer;
}

.register-button{
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: white;
    border-radius: 4px;
    border: none;
    background-color: #f04c51;
    margin: 2vh 0.5vw 2vh 0;
    padding: 0.5em 1.2em;
}

.register-button:hover{
    cursor: pointer;
}

p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}

input{
    border-radius: 4px;
    font-size: 1.2em;
    border: none;
}

.register-input{
    border-radius: 4px;
    border: none;
    padding: 12px 20px;
    font-size: 1.2em;
    background-color: lightgray;
}

.register-label{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-text{
    text-align: center;
}
.flex-grid{
    grid-area: main;
    max-width: 100%;
    margin-bottom: 0;
}

.grid-item{
    flex: 1;
    max-width: 30%;
    max-height: 30%;
}

.Toastify__toast--warning {
    color: black;
    font-weight: bold;
}
  
.Toastify__toast--success {
    font-weight: bold;
}

.text-button {
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    cursor: pointer;
}

.text-button:hover {
    color: blue;
    text-decoration: underline;
}

#login-back {
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    cursor: pointer;
  }

.termsandprivacy{
    float: left;
    margin-top: 5vh;
    margin-right: 1vw;
    background-color: transparent;
    background-repeat: no-repeat; 
    border: none;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}