body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  width: inherit;
  height: inherit;
}
/*.appcontainer{
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;

}

.title {
  text-align: center;
  font-family: arial, sans-serif;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;*/
  /*margin-bottom: 10vh;*/
/*}

#students td, #students th {
  border: 1px solid #ddd;
  padding: 8px;
}

#students tr:nth-child(even){background-color: #f2f2f2;}

#students tr:hover {background-color: #ddd;}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
}*/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h2, h3, h4, h5, h6, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.panel {
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
  width: 100vw;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  text-align: center;
  /*text-transform: uppercase;*/
}

.title {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: xx-large;
  padding-bottom: 5vh;
}

td {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  border-top: 1px solid #e1f1ff;
}

th {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px 0;
}

.btn {
  background-color: #e7e7e7;
  border-radius: 15px;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
#wastopwrap{
  width: 90%;
  height: 90%;
  min-width: 1000px;
  min-height: 561px;
  background-color: #e0e2e2;
}

#topwrap{
  width: calc(100vw - var(--scrollbar-width));
  height: calc(100vh - var(--scrollbar-height));
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #e0e2e2;
}

.columnSettings{
  /*float: left;
  width: 33.3%;
  height: 100%;*/
  flex: 1 1 33%;
  margin: 0;
  height: 100%;
}

#col1{
  background-color: #e0e2e2;
}

#col2{
  background-color: #e0e2e2;
}

#col3{
  background-color: #e0e2e2;
}

#col1 > * {
  margin-left: 5%;
  margin-right: 5%;
}

#col3 > * {
  margin-left: 5%;
  margin-right: 5%;
}

.back-btn {
  width: 10%;
  display: inline-block;
  vertical-align:sub;
  margin-right: 1vw;
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  cursor: pointer;
}

.headline-text{
  display: inline-block;
  width: 85%;
  font-size: 2.6vh;
  font-weight: bolder;
}

.subheading-text {
  font-weight: bolder;
  font-size: 2vh;
}

#progress-bar{
  width: 80%;
  margin-right: 1vw;
  flex: 1 1 auto;
}

#col1 input[type=text] {
  /*margin-top: 2vh;
  margin-bottom: 2vh;*/
  font-size: 1.5vh;
  height: 4vh;
  font-family: arial;
  border-radius: 8px;
  border: none;
  width:98%;
  padding-left: 2%;
}

.longform-input{
  padding: 2%;
  font-size: 1.5vh;
  resize: none;
  font-family: arial;
  height: 10vh;
  width: 96%;
  border-radius: 10px;
  border: none;
}

/*
.upload-container{
  width: 100%;
  max-width: 500px;
}
*/ 
.file-container{
  display: flex;
}

.upload-container {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 75%;
  height: 4.7vw;
  display: flex;
  margin-left: 0;
}


.upload-container label{
  float: left;
  margin-top: 1.35vw;
  margin-left: 1vw;
}

.instruction{
  display: inline-block;  
  margin-left: 0.4vw;
  font-size: 0.8vw;
  margin-top:1.9vw;
}

.item-container:nth-of-type(2){
  float: right;
}

.current-selection{
  max-width: 50%;
  /*float: right;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;*/
  display: flex;
  margin: auto;
  margin-right: 1vw;
}

.selected-text{
  font-size: 0.8vw;
  /*float: right;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;
  margin-left: 5%;*/
  display: flex;
  margin: auto;
  margin-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-x {
  display: inline-block;
  background-color: lightgray;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 0.8vw;
  border-radius: 5px;
  border:none;
  cursor: pointer;
  margin-right: 0.05vw;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;
  margin-left: 15%;
  width: 1vw;
  text-align: center;
  text-transform: uppercase;
}

.icon-prev{
  float: right;
  max-width: 4.7vw;
  max-height: 4.7vw;
  margin: auto;
  margin-right: 0;
}
.icon-prev-image{
  max-height: 100%;
  max-width: 100%;
  border-radius: 8px;
}

input[type=file] {
  display: none;
}

.Toastify__toast--warning {
  color: black;
  font-weight: bold;
}

.Toastify__toast--success {
  font-weight: bold;
}

#audio-playback{
  display:inline-grid;
  margin:auto;
  width:80%;
  height:1.25vw;
  margin-bottom: 0.5vw;
  align-self:center;
}

.size-rot-input{
  width: 12%;
  font-size: 1.5vh;
  height: 4vh;
  font-family: arial;
  border-radius: 8px;
  border: none;
  padding-left: 2%;
}

.size-rot-label{
  margin-left: 5%;
  margin-right: 5%;
  font-weight: 600;
  font-size: 1.5vh;
}

.hide-checkbox {
  height: 0;
  width: 0;
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.tags-button {
  display: inline-block;
  text-align: center;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight:600;
  width: 5vw;
  height: 1.25vw;
  padding: 5px;
  font-size: 0.8vw;
  margin-right: 1.5vw;
  border-radius: 12px;
  line-height: 1.25vw;
  border:none;
  cursor: pointer;
}

 .hide-checkbox:checked + .tags-button{
  width: 5vw;
  height: 1.25vw;
  background: black;
  color: white;
}

.supersize-checkbox{ 
  border: none;
  outline: none;
  display: inline-block;
  width: 5%;
  height: 1em;
}

.license-text{
  display: inline-block;
  height: 1em;
  width: 85%;
  font-size: 0.8vw;
  margin-left: 1%;
}

.small-button {
  background-color: white;
  float: right;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  padding: 0.4vw 0.7vw;
  font-size: 1vw;
  margin-top: 0.8vw;
  border-radius: 7px;
  border:none;
  cursor: pointer;
}

.currently-selected-text {
  float: right;
  
  font-size: 0.8vw;
  overflow: hidden;
}

.color-picker {
  display: flex;
  margin: auto;
  border: 1px black solid;
}

.media-controls{
  border:none;
  cursor: pointer;
  background-color:transparent;
  background-repeat:no-repeat;
  margin-right: 1vw;
}
#root{
  width: inherit;
  height: inherit;
}
/*.appcontainer{
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;

}

.title {
  text-align: center;
  font-family: arial, sans-serif;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;*/
  /*margin-bottom: 10vh;*/
/*}

#students td, #students th {
  border: 1px solid #ddd;
  padding: 8px;
}

#students tr:nth-child(even){background-color: #f2f2f2;}

#students tr:hover {background-color: #ddd;}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
}*/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h3, h4, h5, h6, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  background-color: #60a9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.panel {
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
  width: 100vw;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  text-align: center;
  /*text-transform: uppercase;*/
}

.title {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: xx-large;
  padding-bottom: 5vh;
}

td {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  border-top: 1px solid #e1f1ff;
}

th {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px 0;
}

.oldprops_btn {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 2px;
  cursor: pointer;
  float: right;
  border-style: solid;
  border-width: 1px;
  font-size: 1em;
  font-weight: bold;
  color: black;
  border: none;
}

.btn {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 2px;
  cursor: pointer;
  width: 8.8vw;
  height: 2vw;
  border-style: solid;
  border-width: 1px;
  font-size: 0.78vw;
  font-weight: bolder;
  color: black;
  border: none;
  overflow: hidden;
  outline:none;
  /*z-index: 1;*/
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 3vh;
  margin-right: 3vh;
}

.btn-bottom {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  margin: 20px 2px;
  cursor: pointer;
  width: 8.8vw;
  height: 2vw;
  border-style: solid;
  border-width: 1px;
  font-size: 0.78vw;
  font-weight: bolder;
  color: black;
  border: none;
  float: right;
  overflow: hidden;
  outline:none;
  /*z-index: 1;*/
  margin-top: 3vh;
}

.btn-left {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 2px;
  cursor: pointer;
  float: left;
  border-style: solid;
  border-width: 1px;
  font-size: 1em;
  font-weight: bold;
  color: black;
  border: none;
}

.back-btn {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  cursor: pointer;
  padding-left: 0;
  width: 35px;
}

.menu-btn {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  cursor: pointer;
  /*z-index: 1;*/
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 3vh;
  margin-left: 3vh;
}

.bold-label{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  width: 20%;
  display: inline-flex;
}

#privacy-button{
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-size: small;
  font-weight: bold;
  cursor: pointer;
}
.menu-popup {
  position: fixed;
  /*background: #00000050*/
  background-color: transparent;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
 
.menu {
  position: relative;
  width: 100vw;
  margin: 0 auto;
  /*height: auto;*/
  max-width: 170px;
  /*max-height: 170px;
  height: 100vh;*/
  margin-top: calc(3vh + 35px);
  margin-left: calc(3vh + 40px);
  background: #fff;
  border-radius: 10px;
  padding: 15px 10px 0 10px;
  border: 1px solid #999;
  overflow: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.menu li {
  padding: 3px 0;
}

.menu li:last-child {
  margin-bottom: 10px;
}

.menu li button {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  border-bottom: solid black;
  border-width: 1px;
  cursor: pointer;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size:medium;
}

.menu li:hover {
  outline: solid black;
  outline-width: 1px;
}

.menu li button:hover {
  border: none;
}
.root-panel {
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
  width: 100vw;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: xx-large;
  padding-bottom: 5vh;
}

td {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  border-top: 1px solid #e1f1ff;
}

th {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px 0;
}
.selectionmenu-popup {
  position: fixed;
  /*background: #00000050*/
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
 
.selectionmenu {
  position: relative;
  width: 100vw;
  margin: 0 auto;
  /*height: auto;*/
  max-width: 170px;
  /*max-height: 170px;
  height: 100vh;*/
  margin-top: calc(3vh + 2vw);
  margin-right: calc(3vh + 8.8vw);
  background: #fff;
  border-radius: 10px;
  padding: 15px 10px 0 10px;
  border: 1px solid #999;
  overflow: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.selectionmenu li {
  padding: 3px 0;
}

.selectionmenu li:last-child {
  margin-bottom: 10px;
}

.selectionmenu li button {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  /*border-bottom: solid black;*/
  border-width: 1px;
  cursor: pointer;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size:small;
}

.selectionmenu li:first-child {
  margin-right: 10px;
}

.selectionmenu li:last-child {
  margin-left: 10px;
}

.selectionmenu li:hover {
  outline: solid black;
  outline-width: 1px;
}

.selectionmenu li button:hover {
  border:none;
}

.sidewaysli {
  display: inline-block;
  /* You can also add some margins here to make it look prettier */
  zoom:1;
  *display:inline;
  /* this fix is needed for IE7- */
}
.privacy-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .popup-text {
    position: relative;
    width: 80vw;
    margin: 0 auto;
    height: auto;
    max-height: 80%;
    margin-top: 7vh;
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  .privacy-close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: 8vw;
    top: 5.5vh;
    background: #ededed;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 30px;
  }

  .privacy-ul {
    list-style-type: circle;
    list-style-position: inside;
  }

  .better-h2 {
    font-weight: bolder;
    font-size: large;
  }
.container{
    width: 100vw;
    height: 100vh;

}
.leftPanel {
    height: 100%;
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6fccdc;
}
.rightPanel {
    height: 100%;
    width: 50%;
    float: right;
    background-color: #f04c51;
    display: grid;
    grid-template-columns: 1fr;

    grid-template-areas: 
    'header'
    'main'
    'footer';
}

.login-wrapper{
    max-width: 75%;
}

#logo{
    padding-right: 2vh;
    padding-top: 2vh;
    float: right;
    max-width: 3vw;
}

.homelogin-button{
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    margin: 2vh 0.5vw 2vh 0;
    padding: 0.3em 1.2em;
}

.homelogin-button:hover{
    cursor: pointer;
}

.register-button{
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: white;
    border-radius: 4px;
    border: none;
    background-color: #f04c51;
    margin: 2vh 0.5vw 2vh 0;
    padding: 0.5em 1.2em;
}

.register-button:hover{
    cursor: pointer;
}

p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}

input{
    border-radius: 4px;
    font-size: 1.2em;
    border: none;
}

.register-input{
    border-radius: 4px;
    border: none;
    padding: 12px 20px;
    font-size: 1.2em;
    background-color: lightgray;
}

.register-label{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-text{
    text-align: center;
}
.flex-grid{
    grid-area: main;
    max-width: 100%;
    margin-bottom: 0;
}

.grid-item{
    flex: 1 1;
    max-width: 30%;
    max-height: 30%;
}

.Toastify__toast--warning {
    color: black;
    font-weight: bold;
}
  
.Toastify__toast--success {
    font-weight: bold;
}

.text-button {
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    cursor: pointer;
}

.text-button:hover {
    color: blue;
    text-decoration: underline;
}

#login-back {
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    cursor: pointer;
  }

.termsandprivacy{
    float: left;
    margin-top: 5vh;
    margin-right: 1vw;
    background-color: transparent;
    background-repeat: no-repeat; 
    border: none;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
 
.box {
  position: relative;
  width: 30%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: 33%;
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 30px;
}
.profilepanel {
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
  width: 100vw;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  text-align: left;
  /*text-transform: uppercase;*/
}

.title {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: xx-large;
  padding-bottom: 5vh;
}

.save-btn {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 2px;
  cursor: pointer;
  float: right;
  border-style: solid;
  border-width: 1px;
  font-size: 1em;
  font-weight: bold;
  color: black;
  border: none;
}

.bold-label{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  width: 20%;
  display: inline-flex;
}

.div-padding{
  margin: 20px 30px;
}

.div-padding-pic{
  margin: 20px 30px;
  height: 100px;
}
.profiletextinput{
  display: inline-block;
  float: right;
  width: 70%;
  outline: black solid 1px;
}

.profilepiccontainer{
  display: inline-block;
  float: right;
  width: 70%;
}

.profilefileinput{
  display: inline-block;
  margin-left: 0;
  width: 100px;
  background-color: white;
}

.inputtext{
  display: inline-block;
  float:center;
}

.profilepiccontainer input[type=file] {
  display: none;
}

.profilelonginput{
  display: inline-block;
  float: right;
  resize: none;
  height: 70px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 70%;
}
.parent-tag {
    border: 1px solid gray; 
    width: 70%; 
    height: 5em; 
    float: right;
    overflow: scroll;
    overflow-x: hidden;
}

.tag-input {
    width: 5em; 
    border: none;
}

.tag-input:focus {
    outline:none
}

.tag-item{
  max-width: 4em;
  max-height: 1em;
}

.tag-text{
  display: inline-block;
  text-overflow: ellipsis;
}

.tag-btn{
    display: inline-block;
    position: relative;
    width: 7em;
    height: 2em;
    text-align: left;
    display: inline-block;
}

.tag-btn img {  
	display: inline-block;
    float: right;
    border-radius: 5px;
    width: 15px;
    height: 15px;
}

.tag-btn span{
    display:inline-block;
    max-width: 4.5em;
    vertical-align:left;
    white-space: nowrap; 
    overflow:hidden;
    text-overflow:ellipsis;
}

.tag-container{
    display: inline-block;
    position: relative;
    max-width: 7em;
    height: 1.7em;
    border: 1px solid black;
    border-radius: 7%;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: white;
}

.texedit-btn{
    display: inline-block;
    position: relative;
    max-width: 5em;
    height: 2em;
    text-align: left;
    display: inline-block;
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    cursor: pointer;
}

.btnspan{
    display:inline-block;
    max-width: 4.5em;
    vertical-align:left;
    white-space: nowrap; 
    overflow:hidden;
    text-overflow:ellipsis;
}

.btnimg{
    display: inline-block;
    float: right;
    border-radius: 5px;
    max-width: 2em;
    height: 2em;
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    cursor: pointer;
}

.btninput{
    border: none;
    font-size: small;
}

.btninput:focus{
    outline:none;
}
