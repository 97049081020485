.menu-popup {
  position: fixed;
  /*background: #00000050*/
  background-color: transparent;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
 
.menu {
  position: relative;
  width: 100vw;
  margin: 0 auto;
  /*height: auto;*/
  max-width: 170px;
  /*max-height: 170px;
  height: 100vh;*/
  margin-top: calc(3vh + 35px);
  margin-left: calc(3vh + 40px);
  background: #fff;
  border-radius: 10px;
  padding: 15px 10px 0 10px;
  border: 1px solid #999;
  overflow: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.menu li {
  padding: 3px 0;
}

.menu li:last-child {
  margin-bottom: 10px;
}

.menu li button {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  border-bottom: solid black;
  border-width: 1px;
  cursor: pointer;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size:medium;
}

.menu li:hover {
  outline: solid black;
  outline-width: 1px;
}

.menu li button:hover {
  border: none;
}