#root{
  width: inherit;
  height: inherit;
}
/*.appcontainer{
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;

}

.title {
  text-align: center;
  font-family: arial, sans-serif;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;*/
  /*margin-bottom: 10vh;*/
/*}

#students td, #students th {
  border: 1px solid #ddd;
  padding: 8px;
}

#students tr:nth-child(even){background-color: #f2f2f2;}

#students tr:hover {background-color: #ddd;}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
}*/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h3, h4, h5, h6, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  background-color: #60a9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.panel {
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
  width: 100vw;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  text-align: center;
  /*text-transform: uppercase;*/
}

.title {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: xx-large;
  padding-bottom: 5vh;
}

td {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  border-top: 1px solid #e1f1ff;
}

th {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px 0;
}

.oldprops_btn {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 2px;
  cursor: pointer;
  float: right;
  border-style: solid;
  border-width: 1px;
  font-size: 1em;
  font-weight: bold;
  color: black;
  border: none;
}

.btn {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 2px;
  cursor: pointer;
  width: 8.8vw;
  height: 2vw;
  border-style: solid;
  border-width: 1px;
  font-size: 0.78vw;
  font-weight: bolder;
  color: black;
  border: none;
  overflow: hidden;
  outline:none;
  /*z-index: 1;*/
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 3vh;
  margin-right: 3vh;
}

.btn-bottom {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  margin: 20px 2px;
  cursor: pointer;
  width: 8.8vw;
  height: 2vw;
  border-style: solid;
  border-width: 1px;
  font-size: 0.78vw;
  font-weight: bolder;
  color: black;
  border: none;
  float: right;
  overflow: hidden;
  outline:none;
  /*z-index: 1;*/
  margin-top: 3vh;
}

.btn-left {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0.5em 1.2em;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 2px;
  cursor: pointer;
  float: left;
  border-style: solid;
  border-width: 1px;
  font-size: 1em;
  font-weight: bold;
  color: black;
  border: none;
}

.back-btn {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  cursor: pointer;
  padding-left: 0;
  width: 35px;
}

.menu-btn {
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;
  outline:none;
  cursor: pointer;
  /*z-index: 1;*/
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 3vh;
  margin-left: 3vh;
}

.bold-label{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  width: 20%;
  display: inline-flex;
}

#privacy-button{
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-size: small;
  font-weight: bold;
  cursor: pointer;
}